import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addSalesPerson,
  getSalesPersons,
  updateSalesPerson,
} from "../services/salesPersonApi";
import SalesPerson from "../models/salesPerson";

interface SalesPersonState {
  loading: boolean;
  error: string | null;
  salesPersons: SalesPerson[];
  salesPersonAddLoading: boolean;
  selesPersonAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  salesPersons: [],
  salesPersonAddLoading: false,
  selesPersonAddError: false,
} as SalesPersonState;

const salesPersonSlice = createSlice({
  name: "salesPerson",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSalesPersons.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSalesPersons.fulfilled,
        (state, action: PayloadAction<SalesPerson[]>) => {
          state.loading = false;
          state.error = null;
          state.salesPersons = action.payload;
        }
      )
      .addCase(
        getSalesPersons.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      )

      .addCase(addSalesPerson.pending, (state, action) => {
        state.salesPersonAddLoading = true;
        state.selesPersonAddError = false;
      })
      .addCase(
        addSalesPerson.fulfilled,
        (state, action: PayloadAction<SalesPerson>) => {
          state.salesPersonAddLoading = false;
          state.selesPersonAddError = false;
          state.salesPersons?.unshift(action.payload);
        }
      )
      .addCase(addSalesPerson.rejected, (state, action: PayloadAction<any>) => {
        state.salesPersonAddLoading = false;
        state.selesPersonAddError = true;
      })

      .addCase(updateSalesPerson.pending, (state, action) => {
        state.salesPersonAddLoading = true;
        state.selesPersonAddError = false;
      })
      .addCase(
        updateSalesPerson.fulfilled,
        (state, action: PayloadAction<SalesPerson>) => {
          state.salesPersonAddLoading = false;
          state.selesPersonAddError = false;

          state.salesPersons = state.salesPersons?.map((salesPerson) =>
            salesPerson._id === action.payload._id
              ? action.payload
              : salesPerson
          );
        }
      )
      .addCase(
        updateSalesPerson.rejected,
        (state, action: PayloadAction<any>) => {
          state.salesPersonAddLoading = false;
          state.selesPersonAddError = true;
        }
      );
  },
});

export default salesPersonSlice.reducer;
