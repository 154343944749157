import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  userLogin,
  userOnboard,
  userForgotPassword,
  userResetPassword,
} from "../services/authApi";
import Login from "../models/login";
import OnBoard from "../models/onBoard";

interface AuthState {
  loading: boolean;
  error: Boolean;
  userInfo: any;
  userToken: String;
  forgotPassword: any;
  resetPassword: any;
  success: boolean;
}

const initialState = {
  loading: false,
  error: false,
  userInfo: null,
  userToken: "",
  forgotPassword: null,
  resetPassword: null,
  success: false,
} as AuthState;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLogout: (state) => {
      state.loading = false;
      state.userInfo = null;
      state.userToken = "";
      state.error = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(userLogin.fulfilled, (state, action: PayloadAction<Login>) => {
        state.loading = false;
        state.error = false;
        state.userInfo = action.payload;
      })
      .addCase(userLogin.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(userOnboard.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(userOnboard.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = false;
        state.userInfo = action.payload;
      })
      .addCase(userOnboard.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(userForgotPassword.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(
        userForgotPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = false;
          state.forgotPassword = action.payload;
        }
      )
      .addCase(
        userForgotPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
        }
      )

      .addCase(userResetPassword.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(
        userResetPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = false;
          state.resetPassword = action.payload;
        }
      )
      .addCase(
        userResetPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = true;
        }
      );
  },
});

export const { userLogout } = authSlice.actions;
export default authSlice.reducer;
