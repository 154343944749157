import axios from "axios";

const development =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

let baseURL = process.env.REACT_APP_EASYPLAY_API_BASEURL;
if (!development) baseURL = process.env.REACT_APP_EASYPLAY_API_BASEURL;

const API = axios.create({ baseURL: baseURL });

// Request Interceptor
API.interceptors.request.use(
  (config) => {
    const tokenData = localStorage.getItem("authToken") || "{}";
    let parsedData = JSON.parse(tokenData);
    let token = parsedData?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor (optional, for handling errors globally)
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }

    if (error.response?.status === 403) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default API;
