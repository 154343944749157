import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import {
  addReferral,
  getReferrals,
  updateReferral,
} from "../services/referralApi";
import Referral from "../models/referral";

interface ReferralState {
  loading: boolean;
  error: string | null;
  referrals: Referral[];
  referralAddLoading: boolean;
  referralAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  referrals: [],
  referralAddLoading: false,
  referralAddError: false,
} as ReferralState;

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getReferrals.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getReferrals.fulfilled,
        (state, action: PayloadAction<Referral[]>) => {
          state.loading = false;
          state.error = null;
          state.referrals = action.payload;
        }
      )
      .addCase(getReferrals.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addReferral.pending, (state, action) => {
        state.referralAddLoading = true;
        state.referralAddError = false;
        state.error = null;
      })
      .addCase(
        addReferral.fulfilled,
        (state, action: PayloadAction<Referral>) => {
          state.referralAddLoading = false;
          state.referralAddError = false;
          state.error = null;
          state.referrals?.unshift(action.payload);
        }
      )
      .addCase(addReferral.rejected, (state, action: PayloadAction<any>) => {
        state.referralAddLoading = false;
        state.referralAddError = true;
        state.error = action.payload;
      })

      .addCase(updateReferral.pending, (state, action) => {
        state.referralAddLoading = true;
        state.referralAddError = false;
        state.error = null;
      })
      .addCase(
        updateReferral.fulfilled,
        (state, action: PayloadAction<Referral>) => {
          state.referralAddLoading = false;
          state.referralAddError = false;
          state.error = null;

          state.referrals = state.referrals?.map((referral) =>
            referral._id === action.payload._id ? action.payload : referral
          );
        }
      )
      .addCase(updateReferral.rejected, (state, action: PayloadAction<any>) => {
        state.referralAddLoading = false;
        state.referralAddError = true;
        state.error = action.payload;
      });
  },
});

export default referralSlice.reducer;
