import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import {
  addMachineCoin,
  getMachineCoins,
  updateMachineCoin,
  getMachineCoinsByDate,
} from "../services/machineCoinsApi";
import machineCoin from "../models/machineCoin";

interface MachineCoinState {
  loading: boolean;
  error: string | null;
  machineCoins: machineCoin[];
  machineCoinsReport: any | null;
  machineCoinAddLoading: boolean;
  machineCoinAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  machineCoins: [],
  machineCoinsReport: [],
  machineCoinAddLoading: false,
  machineCoinAddError: false,
} as MachineCoinState;

const machineCoinSlice = createSlice({
  name: "arcadeMachines",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMachineCoins.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getMachineCoins.fulfilled,
        (state, action: PayloadAction<machineCoin[]>) => {
          state.loading = false;
          state.error = null;
          state.machineCoins = action.payload;
        }
      )
      .addCase(
        getMachineCoins.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getMachineCoinsByDate.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getMachineCoinsByDate.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.loading = false;
          state.error = null;
          state.machineCoinsReport = action.payload;
        }
      )
      .addCase(
        getMachineCoinsByDate.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(addMachineCoin.pending, (state, action) => {
        state.machineCoinAddLoading = true;
        state.machineCoinAddError = false;
        state.error = null;
      })
      .addCase(
        addMachineCoin.fulfilled,
        (state, action: PayloadAction<machineCoin>) => {
          state.machineCoinAddLoading = false;
          state.machineCoinAddError = false;
          state.error = null;
          state.machineCoins?.unshift(action.payload);
        }
      )
      .addCase(addMachineCoin.rejected, (state, action: PayloadAction<any>) => {
        state.machineCoinAddLoading = false;
        state.machineCoinAddError = true;
        state.error = action.payload;
      })

      .addCase(updateMachineCoin.pending, (state, action) => {
        state.machineCoinAddLoading = true;
        state.machineCoinAddError = false;
        state.error = null;
      })
      .addCase(
        updateMachineCoin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.machineCoinAddLoading = false;
          state.machineCoinAddError = false;
          state.error = null;

          state.machineCoins = state.machineCoins?.map((machineCoin: any) =>
            machineCoin?._id === action.payload._id
              ? action.payload
              : machineCoin
          );
        }
      )
      .addCase(
        updateMachineCoin.rejected,
        (state, action: PayloadAction<any>) => {
          state.machineCoinAddLoading = false;
          state.machineCoinAddError = true;
          state.error = action.payload;
        }
      );
  },
});

export default machineCoinSlice.reducer;
