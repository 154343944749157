import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import SalesPerson from "../models/salesPerson";

export const getSalesPersons = createAsyncThunk(
  "salesPerson/getSalesPersons",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/users?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addSalesPerson = createAsyncThunk(
  "salesPerson/addSalesPerson",
  async (salesPerson: SalesPerson, { rejectWithValue }) => {
    try {
      const response = await API.post("users", salesPerson);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSalesPerson = createAsyncThunk(
  "salesPerson/updateSalesPerson",
  async (salesPerson: SalesPerson, { rejectWithValue }) => {
    try {
      let sendData = { _id: salesPerson._id, isActive: salesPerson.isActive };
      const response = await API.put(`users/${salesPerson._id}`, sendData);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSalesPerson = createAsyncThunk(
  "salesPerson/deleteSalesPerson",
  async (_id: string) => {
    try {
      const response = await API.delete(`users/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
