import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

export const createCheckoutWithPayment = createAsyncThunk(
  "checkoutPlans/createCheckoutWithPayment",
  async (checkoutData: any, { rejectWithValue }) => {
    try {
      const response = await API.post("checkout", checkoutData);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerSoftplayPlans = createAsyncThunk(
  "checkoutPlans/getCustomerPlans",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        customer: getParams.customerId,
        vendor: getParams.vendorId,
        gameType: getParams.gameType,
      })
    );
    try {
      const response = await API.get(
        `/checkout?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerPlaystationPlans = createAsyncThunk(
  "checkoutPlans/getCustomerPlaystationPlans",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        customer: getParams.customerId,
        vendor: getParams.vendorId,
        gameType: getParams.gameType,
      })
    );
    try {
      const response = await API.get(
        `/checkout?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerComboPlans = createAsyncThunk(
  "checkoutPlans/getCustomerComboPlans",
  async (getParams: any, { rejectWithValue }) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        customer: getParams.customerId,
        vendor: getParams.vendorId,
        gameType: getParams.gameType,
      })
    );
    try {
      const response = await API.get(
        `/checkout?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userOnboard = createAsyncThunk(
  "checkoutPlans/userOnboard",
  async (onBoard: any, { rejectWithValue }) => {
    try {
      const response = await API.post("vendors/onboard", onBoard);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorComboCheckouts = createAsyncThunk(
  "checkoutPlans/getVendorComboCheckouts",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/checkout/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=combo`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorSoftplayCheckouts = createAsyncThunk(
  "checkoutPlans/getVendorSoftplayCheckouts",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/checkout/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=softplay`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorPlaystationCheckouts = createAsyncThunk(
  "checkoutPlans/getVendorPlaystationCheckouts",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/checkout/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=playstation`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVendorArcadeCheckouts = createAsyncThunk(
  "checkoutPlans/getVendorArcadeCheckouts",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/checkout/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=arcadegames`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerGameLifes = createAsyncThunk(
  "checkoutPlans/getCustomerGameLifes",
  async (getParams: any, { rejectWithValue }) => {
    try {
      const response = await API.post(`/checkout/remaining-lifes`, getParams);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
