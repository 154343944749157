import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

export const getInQueueSoftplayPlayers = createAsyncThunk(
  "playersBucket/playersBucketSoftplay",
  async (getParams: any) => {
    try {
      const response = await API.get(
        `/players-bucket/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=${getParams.gameType}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getInQueuePlaystationPlayers = createAsyncThunk(
  "playersBucket/playersBucketPlaystation",
  async (getParams: any) => {
    try {
      const response = await API.get(
        `/players-bucket/get-by-day?vendorId=${getParams.vendorId}&date=${getParams.date}&gameType=${getParams.gameType}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updatePlayerBucketSoftplay = createAsyncThunk(
  "playersBucket/updatePlayerBucketSoftplay",
  async (playerBucket: any) => {
    try {
      const response = await API.put(
        `players-bucket/${playerBucket._id}`,
        playerBucket
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updatePlayerBucketPlaystation = createAsyncThunk(
  "playersBucket/updatePlayerBucketPlaystation",
  async (playerBucket: any) => {
    try {
      const response = await API.put(
        `players-bucket/${playerBucket._id}`,
        playerBucket
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
