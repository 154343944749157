import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import ArcadeMachines from "../models/ArcadeMachines";

export const getArcadeMachines = createAsyncThunk(
  "arcadeMachines/getArcadeMachines",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/machine-list?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addArcadeMachines = createAsyncThunk(
  "arcadeMachines/addArcadeMachines",
  async (arcadeMachine: ArcadeMachines, { rejectWithValue }) => {
    try {
      const response = await API.post("machine-list", arcadeMachine);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateArcadeMachines = createAsyncThunk(
  "arcadeMachines/updateArcadeMachines",
  async (arcadeMachine: ArcadeMachines, { rejectWithValue }) => {
    try {
      const response = await API.put(
        `machine-list/${arcadeMachine._id}`,
        arcadeMachine
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteArcadeMachines = createAsyncThunk(
  "arcadeMachines/deleteArcadeMachines",
  async (_id: string) => {
    try {
      const response = await API.delete(`machine-list/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
