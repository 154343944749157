import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAppliedCoupons } from "../services/appliedCouponsApi";
import AppliedCoupons from "../models/AppliedCoupons";

interface AppliedCouponsState {
  loading: boolean;
  error: string | null;
  appliedCoupons: AppliedCoupons[];
}

const initialState = {
  loading: false,
  error: null,
  appliedCoupons: [],
} as AppliedCouponsState;

const appliedCouponsSlice = createSlice({
  name: "appliedCoupons",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAppliedCoupons.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAppliedCoupons.fulfilled,
        (state, action: PayloadAction<AppliedCoupons[]>) => {
          state.loading = false;
          state.error = null;
          state.appliedCoupons = action.payload;
        }
      )
      .addCase(
        getAppliedCoupons.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.error = action.payload;
        }
      );
  },
});

export default appliedCouponsSlice.reducer;
