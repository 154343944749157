import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";

export const getAppliedCoupons = createAsyncThunk(
  "appliedCoupons/getAppliedCoupons",
  async (getProps: any) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({
        vendor: getProps.vendor,
        customer: getProps.customer,
        // plan: getProps.plan,
      })
    );
    try {
      const response = await API.get(
        `/applied-coupon?limit=all&filter=${URIDecodedVendor}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
