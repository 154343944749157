import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Referral from "../models/referral";

export const getReferrals = createAsyncThunk(
  "referral/getReferrals",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/referals?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addReferral = createAsyncThunk(
  "referral/addReferral",
  async (referral: Referral, { rejectWithValue }) => {
    try {
      const response = await API.post("referals", referral);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateReferral = createAsyncThunk(
  "referral/updateReferral",
  async (referral: Referral, { rejectWithValue }) => {
    try {
      const response = await API.put(`referals/${referral._id}`, referral);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteReferral = createAsyncThunk(
  "referral/deleteReferral",
  async (_id: string) => {
    try {
      const response = await API.delete(`referals/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
