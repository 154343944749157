import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Addons from "../models/addons";

export const getAddons = createAsyncThunk(
  "addon/getAddons",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/addons?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addAddon = createAsyncThunk(
  "addon/addAddon",
  async (addon: Addons, { rejectWithValue }) => {
    try {
      const response = await API.post("addons", addon);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAddon = createAsyncThunk(
  "addon/updateAddon",
  async (addon: Addons) => {
    try {
      const response = await API.put(`addons/${addon._id}`, addon);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteAddon = createAsyncThunk(
  "addon/deleteAddon",
  async (_id: string) => {
    try {
      const response = await API.delete(`addons/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
