import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addVendor,
  getVendors,
  updateVendor,
  getVendorById,
  resendVendorOnboardLink,
  sendReportToAdmins,
} from "../services/vendorApi";
import Vendor from "../models/vendor";

interface VendorState {
  loading: boolean;
  error: string | null;
  vendors: Vendor[];
  vendorAddLoading: boolean;
  vendorAddError: boolean | false;
  selectedVendor: Vendor | null;
  vendorSalesReport: any | null;
}

const initialState = {
  loading: false,
  error: null,
  vendors: [],
  vendorAddLoading: false,
  vendorAddError: false,
  selectedVendor: null,
  vendorSalesReport: null,
} as VendorState;

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getVendors.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendors.fulfilled,
        (state, action: PayloadAction<Vendor[]>) => {
          state.loading = false;
          state.error = null;
          state.vendors = action.payload;
        }
      )
      .addCase(getVendors.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addVendor.pending, (state, action) => {
        state.vendorAddLoading = true;
        state.vendorAddError = false;
        state.error = null;
      })
      .addCase(addVendor.fulfilled, (state, action: PayloadAction<Vendor>) => {
        state.vendorAddLoading = false;
        state.vendorAddError = false;
        state.vendors?.unshift(action.payload);
        state.error = null;
      })
      .addCase(addVendor.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.vendorAddLoading = false;
        state.vendorAddError = true;
      })

      .addCase(resendVendorOnboardLink.pending, (state, action) => {
        state.vendorAddLoading = true;
        state.vendorAddError = false;
        state.error = null;
      })
      .addCase(
        resendVendorOnboardLink.fulfilled,
        (state, action: PayloadAction<Vendor>) => {
          state.vendorAddLoading = false;
          state.vendorAddError = false;
          state.vendors = state.vendors?.map((vendor) =>
            vendor._id === action.payload._id
              ? { ...vendor, status: action.payload.status }
              : vendor
          );
          state.error = null;
        }
      )
      .addCase(
        resendVendorOnboardLink.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.vendorAddLoading = false;
          state.vendorAddError = true;
        }
      )

      .addCase(getVendorById.pending, (state, action) => {
        state.error = null;
      })
      .addCase(
        getVendorById.fulfilled,
        (state, action: PayloadAction<Vendor>) => {
          state.selectedVendor = action.payload;
          state.error = null;
        }
      )
      .addCase(getVendorById.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })

      .addCase(updateVendor.pending, (state, action) => {
        state.vendorAddLoading = true;
        state.vendorAddError = false;
        state.error = null;
      })
      .addCase(
        updateVendor.fulfilled,
        (state, action: PayloadAction<Vendor>) => {
          state.vendorAddLoading = false;
          state.vendorAddError = false;

          state.vendors = state.vendors?.map((vendor) =>
            vendor._id === action.payload._id ? action.payload : vendor
          );
          state.error = null;
        }
      )
      .addCase(updateVendor.rejected, (state, action: PayloadAction<any>) => {
        state.vendorAddLoading = false;
        state.vendorAddError = true;
        state.error = action.payload;
      })

      .addCase(sendReportToAdmins.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        sendReportToAdmins.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.vendorSalesReport = action.payload;
          state.error = null;
        }
      )
      .addCase(
        sendReportToAdmins.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.vendorAddError = true;
        }
      );
  },
});

export default vendorSlice.reducer;
