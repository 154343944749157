import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Coupons from "../models/coupons";

export const getCoupons = createAsyncThunk(
  "coupon/getCoupons",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/coupons?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}&populate=plan`
      );
      return response.data.data;
      // const getData = response.data.data;
      // const newArr1 = getData.map((coupon: any) => ({
      //   ...coupon,
      //   planName: coupon.plan.planName,
      //   plan: coupon.plan._id,
      // }));
      // return newArr1;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addCoupon = createAsyncThunk(
  "coupon/addCoupon",
  async (coupon: Coupons, { rejectWithValue }) => {
    try {
      const response = await API.post("coupons", coupon);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCoupon = createAsyncThunk(
  "coupon/updateCoupon",
  async (coupon: Coupons) => {
    try {
      const response = await API.put(`coupons/${coupon._id}`, coupon);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const deleteCoupon = createAsyncThunk(
  "coupon/deleteCoupon",
  async (_id: string) => {
    try {
      const response = await API.delete(`coupons/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
