import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Plan from "../models/plan";

export const getPlans = createAsyncThunk(
  "plan/getPlans",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ vendor: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/plans?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addPlan = createAsyncThunk(
  "plan/addPlan",
  async (plan: Plan, { rejectWithValue }) => {
    try {
      const response = await API.post("plans", plan);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updatePlan = createAsyncThunk(
  "plan/updatePlan",
  async (plan: Plan, { rejectWithValue }) => {
    try {
      const response = await API.put(`plans/${plan._id}`, plan);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deletePlan = createAsyncThunk(
  "plan/deletePlan",
  async (_id: string) => {
    try {
      const response = await API.delete(`plans/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
