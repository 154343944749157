import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { addPlan, getPlans, updatePlan } from "../services/planApi";
import Plan from "../models/plan";

interface PlanState {
  loading: boolean;
  error: string | null;
  plans: Plan[];
  planAddLoading: boolean;
  planAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  plans: [],
  planAddLoading: false,
  planAddError: false,
} as PlanState;

const planSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPlans.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlans.fulfilled, (state, action: PayloadAction<Plan[]>) => {
        state.loading = false;
        state.error = null;
        state.plans = action.payload;
      })
      .addCase(getPlans.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addPlan.pending, (state, action) => {
        state.planAddLoading = true;
        state.planAddError = false;
        state.error = null;
      })
      .addCase(addPlan.fulfilled, (state, action: PayloadAction<Plan>) => {
        state.planAddLoading = false;
        state.planAddError = false;
        state.error = null;
        state.plans?.unshift(action.payload);
      })
      .addCase(addPlan.rejected, (state, action: PayloadAction<any>) => {
        state.planAddLoading = false;
        state.planAddError = true;
        state.error = action.payload;
      })

      .addCase(updatePlan.pending, (state, action) => {
        state.planAddLoading = true;
        state.planAddError = false;
        state.error = null;
      })
      .addCase(updatePlan.fulfilled, (state, action: PayloadAction<Plan>) => {
        state.planAddLoading = false;
        state.planAddError = false;
        state.error = null;

        state.plans = state.plans?.map((plan) =>
          plan._id === action.payload._id ? action.payload : plan
        );
      })
      .addCase(updatePlan.rejected, (state, action: PayloadAction<any>) => {
        state.planAddLoading = false;
        state.planAddError = true;
        state.error = action.payload;
      });
  },
});

export default planSlice.reducer;
