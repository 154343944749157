import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCoupon, getCoupons, updateCoupon } from "../services/couponsApi";
import Coupons from "../models/coupons";

interface CouponState {
  loading: boolean;
  error: string | null;
  coupons: Coupons[];
  couponAddLoading: boolean;
  couponAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  coupons: [],
  couponAddLoading: false,
  couponAddError: false,
} as CouponState;

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCoupons.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCoupons.fulfilled,
        (state, action: PayloadAction<Coupons[]>) => {
          state.loading = false;
          state.error = null;
          state.coupons = action.payload;
        }
      )
      .addCase(getCoupons.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.error = action.payload;
      })

      .addCase(addCoupon.pending, (state, action) => {
        state.couponAddLoading = true;
        state.couponAddError = false;
      })
      .addCase(addCoupon.fulfilled, (state, action: PayloadAction<Coupons>) => {
        state.couponAddLoading = false;
        state.couponAddError = false;
        state.coupons?.unshift(action.payload);
      })
      .addCase(addCoupon.rejected, (state, action: PayloadAction<any>) => {
        state.couponAddLoading = false;
        state.couponAddError = true;
      })

      .addCase(updateCoupon.pending, (state, action) => {
        state.couponAddLoading = true;
        state.couponAddError = false;
      })
      .addCase(
        updateCoupon.fulfilled,
        (state, action: PayloadAction<Coupons>) => {
          state.couponAddLoading = false;
          state.couponAddError = false;

          state.coupons = state.coupons?.map((coupon) =>
            coupon._id === action.payload._id ? action.payload : coupon
          );
        }
      )
      .addCase(updateCoupon.rejected, (state, action: PayloadAction<any>) => {
        state.couponAddLoading = false;
        state.couponAddError = true;
      });
  },
});

export default couponSlice.reducer;
