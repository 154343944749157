import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import {
  getCustomers,
  searchCustomer,
  addCustomer,
  updateCustomer,
  getCustomerCheckouts,
  getCustomerCheckoutHistory,
} from "../services/customerApi";
import Customer from "../models/customer";

interface CustomerState {
  loading: boolean;
  error: string | null;
  customers: Customer[];
  findCustomer: any;
  customerCheckouts: any;
  customerCheckoutHistory: any;
  customerAddLoading: boolean;
  customerAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  customers: [],
  customerCheckouts: [],
  customerCheckoutHistory: [],
  findCustomer: [],
  customerAddLoading: false,
  customerAddError: false,
} as CustomerState;

const customerSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCustomers.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCustomers.fulfilled,
        (state, action: PayloadAction<Customer[]>) => {
          state.loading = false;
          state.error = null;
          state.customers = action.payload;
        }
      )
      .addCase(getCustomers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(searchCustomer.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        searchCustomer.fulfilled,
        (state, action: PayloadAction<Customer[]>) => {
          state.loading = false;
          state.error = null;
          state.findCustomer = action.payload;
        }
      )
      .addCase(searchCustomer.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(addCustomer.pending, (state, action) => {
        state.customerAddLoading = true;
        state.customerAddError = false;
        state.error = null;
      })
      .addCase(
        addCustomer.fulfilled,
        (state, action: PayloadAction<Customer>) => {
          state.customerAddLoading = false;
          state.customerAddError = false;
          state.error = null;
          state.customers?.unshift(action.payload);
        }
      )
      .addCase(addCustomer.rejected, (state, action: PayloadAction<any>) => {
        state.customerAddLoading = false;
        state.customerAddError = true;
        state.error = action.payload;
      })

      .addCase(updateCustomer.pending, (state, action) => {
        state.customerAddLoading = true;
        state.customerAddError = false;
        state.error = null;
      })
      .addCase(
        updateCustomer.fulfilled,
        (state, action: PayloadAction<Customer>) => {
          state.customerAddLoading = false;
          state.customerAddError = false;
          state.error = null;

          state.customers = state.customers?.map((customer) =>
            customer._id === action.payload._id ? action.payload : customer
          );
        }
      )
      .addCase(updateCustomer.rejected, (state, action: PayloadAction<any>) => {
        state.customerAddLoading = false;
        state.customerAddError = true;
        state.error = action.payload;
      })

      .addCase(getCustomerCheckouts.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCustomerCheckouts.fulfilled,
        (state, action: PayloadAction<Customer>) => {
          state.loading = false;
          state.error = null;
          state.customerCheckouts = action.payload;
        }
      )
      .addCase(
        getCustomerCheckouts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getCustomerCheckoutHistory.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCustomerCheckoutHistory.fulfilled,
        (state, action: PayloadAction<Customer>) => {
          state.loading = false;
          state.error = null;
          state.customerCheckoutHistory = action.payload;
        }
      )
      .addCase(
        getCustomerCheckoutHistory.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default customerSlice.reducer;
