import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Vendor from "../models/vendor";

export const getVendors = createAsyncThunk("vendor/getVendors", async () => {
  try {
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    const response = await API.get(`/vendors?limit=all&sort=${URIDecodedSort}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
});

export const getVendorById = createAsyncThunk(
  "vendor/getVendorById",
  async (_id: string) => {
    try {
      const response = await API.get(`vendors/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addVendor = createAsyncThunk(
  "vendor/addVendor",
  async (vendor: Vendor, { rejectWithValue }) => {
    try {
      const response = await API.post("vendors", vendor);
      return response.data.data;
    } catch (error: any) {
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const resendVendorOnboardLink = createAsyncThunk(
  "vendor/resendVendorOnboardLink",
  async (vendor: any, { rejectWithValue }) => {
    try {
      const response = await API.post("vendors/resend-onboard-link", vendor);
      return response.data.data;
    } catch (error: any) {
      throw rejectWithValue(error.response.data.message);
    }
  }
);

export const updateVendor = createAsyncThunk(
  "vendor/updateVendor",
  async (vendor: Vendor, { rejectWithValue }) => {
    try {
      // let sendData = { _id: vendor._id, status: vendor.status };
      const response = await API.put(`vendors/${vendor._id}`, vendor);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteVendor = createAsyncThunk(
  "vendor/deleteVendor",
  async (_id: string) => {
    try {
      const response = await API.delete(`vendors/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendReportToAdmins = createAsyncThunk(
  "vendor/sendReportToAdmins",
  async (report: any, { rejectWithValue }) => {
    try {
      const response = await API.post("vendors/sales-report/v1", report);
      return response.data.data;
    } catch (error: any) {
      throw rejectWithValue(error.response.data.message);
    }
  }
);
