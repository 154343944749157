import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import Login from "../models/login";
import OnBoard from "../models/onBoard";

export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (login: Login, { rejectWithValue }) => {
    try {
      const response = await API.post("auth/login", login);
      localStorage.setItem("authToken", JSON.stringify(response.data.data));
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userOnboard = createAsyncThunk(
  "auth/userOnboard",
  async (onBoard: OnBoard, { rejectWithValue }) => {
    try {
      const response = await API.post("vendors/onboard", onBoard);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userForgotPassword = createAsyncThunk(
  "auth/userForgotPassword",
  async (login: any, { rejectWithValue }) => {
    try {
      const response = await API.post("auth/forgot-password", login);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userResetPassword = createAsyncThunk(
  "auth/userResetPassword",
  async (login: any, { rejectWithValue }) => {
    try {
      const response = await API.post("auth/reset-password", login);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
