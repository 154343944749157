import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "./api";
import machineCoin from "../models/machineCoin";

export const getMachineCoins = createAsyncThunk(
  "machineCoins/getMachineCoins",
  async (_id: String) => {
    const URIDecodedVendor = encodeURIComponent(
      JSON.stringify({ machine: _id })
    );
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/coins?limit=all&filter=${URIDecodedVendor}&sort=${URIDecodedSort}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addMachineCoin = createAsyncThunk(
  "machineCoins/addMachineCoin",
  async (machineCoin: any, { rejectWithValue }) => {
    try {
      const response = await API.post("coins", machineCoin);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateMachineCoin = createAsyncThunk(
  "machineCoins/updateMachineCoin",
  async (machineCoin: any, { rejectWithValue }) => {
    try {
      const response = await API.put(`coins/${machineCoin._id}`, machineCoin);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteMachineCoin = createAsyncThunk(
  "machineCoins/deleteMachineCoin",
  async (_id: string) => {
    try {
      const response = await API.delete(`coins/${_id}`);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getMachineCoinsByDate = createAsyncThunk(
  "machineCoins/getMachineCoinsByDate",
  async (getProps: any) => {
    const URIDecodedSort = encodeURIComponent(
      JSON.stringify({ createdAt: -1 })
    );
    try {
      const response = await API.get(
        `/coins/by-date?vendorId=${getProps.vendorId}&fromDate=${getProps.fromDate}&toDate=${getProps.toDate}`
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
