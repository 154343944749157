import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import {
  addArcadeMachines,
  getArcadeMachines,
  updateArcadeMachines,
} from "../services/arcadeMachineApi";
import ArcadeMachines from "../models/ArcadeMachines";

interface ArcadeMachineState {
  loading: boolean;
  error: string | null;
  arcadeMachines: ArcadeMachines[];
  arcadeMachineAddLoading: boolean;
  arcadeMachineAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  arcadeMachines: [],
  arcadeMachineAddLoading: false,
  arcadeMachineAddError: false,
} as ArcadeMachineState;

const arcadeMachineSlice = createSlice({
  name: "arcadeMachines",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getArcadeMachines.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getArcadeMachines.fulfilled,
        (state, action: PayloadAction<ArcadeMachines[]>) => {
          state.loading = false;
          state.error = null;
          state.arcadeMachines = action.payload;
        }
      )
      .addCase(
        getArcadeMachines.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(addArcadeMachines.pending, (state, action) => {
        state.arcadeMachineAddLoading = true;
        state.arcadeMachineAddError = false;
        state.error = null;
      })
      .addCase(
        addArcadeMachines.fulfilled,
        (state, action: PayloadAction<ArcadeMachines>) => {
          state.arcadeMachineAddLoading = false;
          state.arcadeMachineAddError = false;
          state.error = null;
          state.arcadeMachines?.unshift(action.payload);
        }
      )
      .addCase(
        addArcadeMachines.rejected,
        (state, action: PayloadAction<any>) => {
          state.arcadeMachineAddLoading = false;
          state.arcadeMachineAddError = true;
          state.error = action.payload;
        }
      )

      .addCase(updateArcadeMachines.pending, (state, action) => {
        state.arcadeMachineAddLoading = true;
        state.arcadeMachineAddError = false;
        state.error = null;
      })
      .addCase(
        updateArcadeMachines.fulfilled,
        (state, action: PayloadAction<ArcadeMachines>) => {
          state.arcadeMachineAddLoading = false;
          state.arcadeMachineAddError = false;
          state.error = null;

          state.arcadeMachines = state.arcadeMachines?.map((arcadeMachine) =>
            arcadeMachine._id === action.payload._id
              ? action.payload
              : arcadeMachine
          );
        }
      )
      .addCase(
        updateArcadeMachines.rejected,
        (state, action: PayloadAction<any>) => {
          state.arcadeMachineAddLoading = false;
          state.arcadeMachineAddError = true;
          state.error = action.payload;
        }
      );
  },
});

export default arcadeMachineSlice.reducer;
