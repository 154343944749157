import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addAddon, getAddons, updateAddon } from "../services/addonsApi";
import Addons from "../models/addons";

interface AddonState {
  loading: boolean;
  error: string | null;
  addons: Addons[];
  addonAddLoading: boolean;
  addonAddError: boolean | false;
}

const initialState = {
  loading: false,
  error: null,
  addons: [],
  addonAddLoading: false,
  addonAddError: false,
} as AddonState;

const addonSlice = createSlice({
  name: "addon",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAddons.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAddons.fulfilled,
        (state, action: PayloadAction<Addons[]>) => {
          state.loading = false;
          state.error = null;
          state.addons = action.payload;
        }
      )
      .addCase(getAddons.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.error = action.payload;
      })

      .addCase(addAddon.pending, (state, action) => {
        state.addonAddLoading = true;
        state.addonAddError = false;
      })
      .addCase(addAddon.fulfilled, (state, action: PayloadAction<Addons>) => {
        state.addonAddLoading = false;
        state.addonAddError = false;
        state.addons?.unshift(action.payload);
      })
      .addCase(addAddon.rejected, (state, action: PayloadAction<any>) => {
        state.addonAddLoading = false;
        state.addonAddError = true;
      })

      .addCase(updateAddon.pending, (state, action) => {
        state.addonAddLoading = true;
        state.addonAddError = false;
      })
      .addCase(
        updateAddon.fulfilled,
        (state, action: PayloadAction<Addons>) => {
          state.addonAddLoading = false;
          state.addonAddError = false;

          state.addons = state.addons?.map((addon) =>
            addon._id === action.payload._id ? action.payload : addon
          );
        }
      )
      .addCase(updateAddon.rejected, (state, action: PayloadAction<any>) => {
        state.addonAddLoading = false;
        state.addonAddError = true;
      });
  },
});

export default addonSlice.reducer;
