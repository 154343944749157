import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createCheckoutWithPayment,
  getCustomerSoftplayPlans,
  getCustomerPlaystationPlans,
  getCustomerComboPlans,
  getVendorComboCheckouts,
  getVendorSoftplayCheckouts,
  getVendorPlaystationCheckouts,
  getVendorArcadeCheckouts,
} from "../services/checkoutApi";

export interface CheckoutState {
  loading: boolean;
  loadingCustomerSoftplayPlans: boolean;
  loadingCustomerPlaystationPlans: boolean;
  error: string | null;
  selectedSoftPlayPlan: any | null;
  selectedSoftPlayAdultAddon: any | null;
  selectedSoftPlayTimeAddon: any | null;
  selectedSoftPlayCoupon: any | null;
  selectedSoftPlayFinalValue: any | null;
  selectedSoftPlayCouponDiscountValue: any | null;
  selectedSoftplaySummary: any | null;
  selectedArcadeGamesPlan: any | null;
  selectedArcadeGamesCoupon: any | null;
  selectedArcadeGamesFinalValue: any | null;
  selectedArcadeGamesCouponDiscountValue: any | null;
  selectedArcadegamesSummary: any | null;
  selectedPlayStationPlan: any | null;
  selectedPlaystationAdultAddon: any | null;
  selectedPlaystationTimeAddon: any | null;
  selectedPlayStationCoupon: any | null;
  selectedPlaystationFinalValue: any | null;
  selectedPlayStationCouponDiscountValue: any | null;
  selectedPlaystationSummary: any | null;
  totalCheckoutAmount: any | null;
  totalDiscountAmount: any | null;
  totalFinalAmount: any | null;
  checkoutAddLoading: boolean;
  checkoutAddError: boolean | false;
  checkoutData: any | null;
  customerSoftplayPlans: any | null;
  customerPlaystationPlans: any | null;
  selectedComboPlan: any | null;
  selectedComboAdultAddon: any | null;
  selectedComboTimeAddon: any | null;
  selectedComboCoupon: any | null;
  selectedComboFinalValue: any | null;
  selectedComboCouponDiscountValue: any | null;
  selectedComboSummary: any | null;
  customerComboPlans: any | null;
  vendorComboCheckouts: any | null;
  vendorSoftplayCheckouts: any | null;
  vendorPlaystationCheckouts: any | null;
  vendorArcadegamesCheckouts: any | null;
}

const initialState: CheckoutState = {
  loading: false,
  loadingCustomerSoftplayPlans: false,
  loadingCustomerPlaystationPlans: false,
  error: null,
  selectedSoftPlayPlan: null,
  selectedSoftPlayAdultAddon: null,
  selectedSoftPlayTimeAddon: null,
  selectedSoftPlayCoupon: null,
  selectedSoftPlayFinalValue: "",
  selectedSoftPlayCouponDiscountValue: "",
  selectedSoftplaySummary: null,
  selectedArcadeGamesPlan: null,
  selectedArcadeGamesCoupon: null,
  selectedArcadeGamesFinalValue: "",
  selectedArcadeGamesCouponDiscountValue: "",
  selectedArcadegamesSummary: null,
  selectedPlayStationPlan: null,
  selectedPlaystationAdultAddon: null,
  selectedPlaystationTimeAddon: null,
  selectedPlayStationCoupon: null,
  selectedPlaystationFinalValue: "",
  selectedPlayStationCouponDiscountValue: "",
  selectedPlaystationSummary: null,
  totalCheckoutAmount: "",
  totalDiscountAmount: "",
  totalFinalAmount: "",
  checkoutAddLoading: false,
  checkoutAddError: false,
  checkoutData: null,
  customerSoftplayPlans: null,
  customerPlaystationPlans: null,
  selectedComboPlan: null,
  selectedComboAdultAddon: null,
  selectedComboTimeAddon: null,
  selectedComboCoupon: null,
  selectedComboFinalValue: null,
  selectedComboCouponDiscountValue: null,
  selectedComboSummary: null,
  customerComboPlans: null,
  vendorComboCheckouts: null,
  vendorSoftplayCheckouts: null,
  vendorPlaystationCheckouts: null,
  vendorArcadegamesCheckouts: null,
};

export const checkoutSlice = createSlice({
  name: "checkoutPlans",
  initialState,
  reducers: {
    addSoftPlayPlan: (state, action: PayloadAction<any>) => {
      state.selectedSoftPlayPlan = action.payload;
    },
    updateSoftPlayAdultAddon: (state, action: PayloadAction<any>) => {
      state.selectedSoftPlayAdultAddon = action.payload;
    },
    updateSoftPlayTimeAddon: (state, action: PayloadAction<any>) => {
      state.selectedSoftPlayTimeAddon = action.payload;
    },
    updateSoftPlayCoupon: (state, action: PayloadAction<any>) => {
      state.selectedSoftPlayCoupon = action.payload;
    },
    updateSoftPlayCouponDiscountValue: (state) => {
      let finalTotalSoftplay = 0;
      let planPriceSoftplay = 0;
      let discountedValue = 0;

      if (state.selectedSoftPlayPlan) {
        planPriceSoftplay = state.selectedSoftPlayPlan.salePrice;
      }
      let extraAdultPriceSoftplay = 0;
      if (state.selectedSoftPlayAdultAddon) {
        extraAdultPriceSoftplay =
          state.selectedSoftPlayAdultAddon.amount *
          state.selectedSoftPlayAdultAddon.adultCounter;
      }
      let extraTimePriceSoftplay = 0;
      if (state.selectedSoftPlayTimeAddon) {
        if (state.selectedSoftPlayTimeAddon.timeCounter) {
          extraTimePriceSoftplay =
            state.selectedSoftPlayTimeAddon.amount *
            state.selectedSoftPlayTimeAddon.timeCounter;
        } else {
          extraTimePriceSoftplay = state.selectedSoftPlayTimeAddon.amount;
        }
      }

      finalTotalSoftplay =
        planPriceSoftplay + extraAdultPriceSoftplay + extraTimePriceSoftplay;

      if (state.selectedSoftPlayCoupon) {
        let couponDiscount = state.selectedSoftPlayCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          discountedValue = discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalSoftplay - finalTotalSoftplay * calculatePercentage;
          discountedValue = finalTotalSoftplay - calculateAfterDiscount;
        }
      }

      state.selectedSoftPlayCouponDiscountValue = discountedValue;
    },
    updateSoftplayFinalValue: (state) => {
      let finalTotalSoftplay = 0;
      let planPriceSoftplay = 0;

      if (state.selectedSoftPlayPlan) {
        planPriceSoftplay = state.selectedSoftPlayPlan.salePrice;
      }
      let extraAdultPriceSoftplay = 0;
      if (state.selectedSoftPlayAdultAddon) {
        extraAdultPriceSoftplay =
          state.selectedSoftPlayAdultAddon.amount *
          state.selectedSoftPlayAdultAddon.adultCounter;
      }
      let extraTimePriceSoftplay = 0;
      if (state.selectedSoftPlayTimeAddon) {
        if (state.selectedSoftPlayTimeAddon?.timeCounter) {
          extraTimePriceSoftplay =
            state.selectedSoftPlayTimeAddon.amount *
            state.selectedSoftPlayTimeAddon.timeCounter;
        } else {
          extraTimePriceSoftplay = state.selectedSoftPlayTimeAddon.amount;
        }
      }

      finalTotalSoftplay =
        planPriceSoftplay + extraAdultPriceSoftplay + extraTimePriceSoftplay;

      if (state.selectedSoftPlayCoupon) {
        let couponDiscount = state.selectedSoftPlayCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          finalTotalSoftplay = finalTotalSoftplay - discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalSoftplay - finalTotalSoftplay * calculatePercentage;
          finalTotalSoftplay = calculateAfterDiscount;
        }
      }

      state.selectedSoftPlayFinalValue = finalTotalSoftplay;
    },
    addArcadeGamesPlan: (state, action: PayloadAction<any>) => {
      state.selectedArcadeGamesPlan = action.payload;
    },
    updateArcadGamesCoupon: (state, action: PayloadAction<any>) => {
      state.selectedArcadeGamesCoupon = action.payload;
    },
    updateArcadeGamesCouponDiscountValue: (state) => {
      let finalTotalArcadegames = 0;
      let planPriceArcadegames = 0;
      let discountedValue = 0;

      if (state.selectedArcadeGamesPlan) {
        planPriceArcadegames = state.selectedArcadeGamesPlan.salePrice;
      }

      finalTotalArcadegames = planPriceArcadegames;

      if (state.selectedArcadeGamesCoupon) {
        let couponDiscount = state.selectedArcadeGamesCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          discountedValue = discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalArcadegames - finalTotalArcadegames * calculatePercentage;
          discountedValue = finalTotalArcadegames - calculateAfterDiscount;
        }
      }

      state.selectedArcadeGamesCouponDiscountValue = discountedValue;
    },
    updateArcadegamesFinalValue: (state) => {
      let finalTotalArcadegames = 0;
      let planPriceArcadegames = 0;

      if (state.selectedArcadeGamesPlan) {
        planPriceArcadegames = state.selectedArcadeGamesPlan.salePrice;
      }

      finalTotalArcadegames = planPriceArcadegames;

      if (state.selectedArcadeGamesCoupon) {
        let couponDiscount = state.selectedArcadeGamesCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          finalTotalArcadegames = finalTotalArcadegames - discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalArcadegames - finalTotalArcadegames * calculatePercentage;
          finalTotalArcadegames = calculateAfterDiscount;
        }
      }

      state.selectedArcadeGamesFinalValue = finalTotalArcadegames;
    },
    addPlayStationPlan: (state, action: PayloadAction<any>) => {
      state.selectedPlayStationPlan = action.payload;
    },
    updatePlaystationAdultAddon: (state, action: PayloadAction<any>) => {
      state.selectedPlaystationAdultAddon = action.payload;
    },
    updatePlaystationTimeAddon: (state, action: PayloadAction<any>) => {
      state.selectedPlaystationTimeAddon = action.payload;
    },
    updatePlaystationCoupon: (state, action: PayloadAction<any>) => {
      state.selectedPlayStationCoupon = action.payload;
    },
    updatePlaystationCouponDiscountValue: (state) => {
      let finalTotalPlaystation = 0;
      let planPricePlaystation = 0;
      let discountedValue = 0;

      if (state.selectedPlayStationPlan) {
        planPricePlaystation = state.selectedPlayStationPlan.salePrice;
      }
      let extraAdultPricePlaystation = 0;
      if (state.selectedPlaystationAdultAddon) {
        extraAdultPricePlaystation =
          state.selectedPlaystationAdultAddon.amount *
          state.selectedPlaystationAdultAddon.adultCounter;
      }
      let extraTimePricePlaystation = 0;
      if (state.selectedPlaystationTimeAddon) {
        if (state.selectedPlaystationTimeAddon.timeCounter) {
          extraTimePricePlaystation =
            state.selectedPlaystationTimeAddon.amount *
            state.selectedPlaystationTimeAddon.timeCounter;
        } else {
          extraTimePricePlaystation = state.selectedPlaystationTimeAddon.amount;
        }
      }

      finalTotalPlaystation =
        planPricePlaystation +
        extraAdultPricePlaystation +
        extraTimePricePlaystation;

      if (state.selectedPlayStationCoupon) {
        let couponDiscount = state.selectedPlayStationCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          discountedValue = discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalPlaystation - finalTotalPlaystation * calculatePercentage;
          discountedValue = finalTotalPlaystation - calculateAfterDiscount;
        }
      }

      state.selectedPlayStationCouponDiscountValue = discountedValue;
    },
    updatePlaystationFinalValue: (state) => {
      let finalTotalPlaystation = 0;
      let planPricePlaystation = 0;
      if (state.selectedPlayStationPlan) {
        planPricePlaystation = state.selectedPlayStationPlan.salePrice;
      }
      let extraAdultPricePlaystation = 0;
      if (state.selectedPlaystationAdultAddon) {
        extraAdultPricePlaystation =
          state.selectedPlaystationAdultAddon.amount *
          state.selectedPlaystationAdultAddon.adultCounter;
      }
      let extraTimePricePlaystation = 0;
      if (state.selectedPlaystationTimeAddon) {
        if (state.selectedPlaystationTimeAddon.timeCounter) {
          extraTimePricePlaystation =
            state.selectedPlaystationTimeAddon.amount *
            state.selectedPlaystationTimeAddon.timeCounter;
        } else {
          extraTimePricePlaystation = state.selectedPlaystationTimeAddon.amount;
        }
      }

      finalTotalPlaystation =
        planPricePlaystation +
        extraAdultPricePlaystation +
        extraTimePricePlaystation;

      if (state.selectedPlayStationCoupon) {
        let couponDiscount = state.selectedPlayStationCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          finalTotalPlaystation = finalTotalPlaystation - discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalPlaystation - finalTotalPlaystation * calculatePercentage;
          finalTotalPlaystation = calculateAfterDiscount;
        }
      }
      state.selectedPlaystationFinalValue = finalTotalPlaystation;
    },

    addComboPlan: (state, action: PayloadAction<any>) => {
      state.selectedComboPlan = action.payload;
    },
    updateComboAdultAddon: (state, action: PayloadAction<any>) => {
      state.selectedComboAdultAddon = action.payload;
    },
    updateComboTimeAddon: (state, action: PayloadAction<any>) => {
      state.selectedComboTimeAddon = action.payload;
    },
    updateComboCoupon: (state, action: PayloadAction<any>) => {
      state.selectedComboCoupon = action.payload;
    },
    updateComboCouponDiscountValue: (state) => {
      let finalTotalCombo = 0;
      let planPriceCombo = 0;
      let discountedValue = 0;

      if (state.selectedComboPlan) {
        planPriceCombo = state.selectedComboPlan.salePrice;
      }
      let extraAdultPriceCombo = 0;
      if (state.selectedComboAdultAddon) {
        extraAdultPriceCombo =
          state.selectedComboAdultAddon.amount *
          state.selectedComboAdultAddon.adultCounter;
      }
      let extraTimePriceCombo = 0;
      if (state.selectedComboTimeAddon) {
        if (state.selectedComboTimeAddon.timeCounter) {
          extraTimePriceCombo =
            state.selectedComboTimeAddon.amount *
            state.selectedComboTimeAddon.timeCounter;
        } else {
          extraTimePriceCombo = state.selectedComboTimeAddon.amount;
        }

        extraTimePriceCombo = state.selectedComboTimeAddon.amount;
      }

      finalTotalCombo =
        planPriceCombo + extraAdultPriceCombo + extraTimePriceCombo;

      if (state.selectedComboCoupon) {
        let couponDiscount = state.selectedComboCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          discountedValue = discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalCombo - finalTotalCombo * calculatePercentage;
          discountedValue = finalTotalCombo - calculateAfterDiscount;
        }
      }

      state.selectedComboCouponDiscountValue = discountedValue;
    },
    updateComboFinalValue: (state) => {
      let finalTotalCombo = 0;
      let planPriceCombo = 0;

      if (state.selectedComboPlan) {
        planPriceCombo = state.selectedComboPlan.salePrice;
      }
      let extraAdultPriceCombo = 0;
      if (state.selectedComboAdultAddon) {
        extraAdultPriceCombo =
          state.selectedComboAdultAddon.amount *
          state.selectedComboAdultAddon.adultCounter;
      }
      let extraTimePriceCombo = 0;
      if (state.selectedComboTimeAddon) {
        if (state.selectedComboTimeAddon.timeCounter) {
          extraTimePriceCombo =
            state.selectedComboTimeAddon.amount *
            state.selectedComboTimeAddon.timeCounter;
        } else {
          extraTimePriceCombo = state.selectedComboTimeAddon.amount;
        }
      }

      finalTotalCombo =
        planPriceCombo + extraAdultPriceCombo + extraTimePriceCombo;

      if (state.selectedComboCoupon) {
        let couponDiscount = state.selectedComboCoupon.discountType;
        let discountType = couponDiscount?.type;
        let discountValue = couponDiscount?.value;

        if (discountType == "flatamount") {
          finalTotalCombo = finalTotalCombo - discountValue;
        }

        if (discountType == "percentage") {
          let calculatePercentage = discountValue / 100;
          let calculateAfterDiscount =
            finalTotalCombo - finalTotalCombo * calculatePercentage;
          finalTotalCombo = calculateAfterDiscount;
        }
      }

      state.selectedComboFinalValue = finalTotalCombo;
    },
    updateCustomerSoftplayPlans: (state) => {
      state.customerSoftplayPlans = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerSoftplayPlans.pending, (state, action) => {
        state.loadingCustomerSoftplayPlans = true;
        state.error = null;
      })
      .addCase(
        getCustomerSoftplayPlans.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loadingCustomerSoftplayPlans = false;
          state.error = null;
          let filteredSoftplayPlans = action.payload.filter(
            (sp: any) => sp.remainingLife > 0
          );
          state.customerSoftplayPlans = filteredSoftplayPlans;
        }
      )
      .addCase(
        getCustomerSoftplayPlans.rejected,
        (state, action: PayloadAction<any>) => {
          state.loadingCustomerSoftplayPlans = false;
          state.error = action.payload;
        }
      )

      .addCase(getCustomerPlaystationPlans.pending, (state, action) => {
        state.loadingCustomerPlaystationPlans = true;
        state.error = null;
      })
      .addCase(
        getCustomerPlaystationPlans.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loadingCustomerPlaystationPlans = false;
          state.error = null;
          let filteredPlaystationPlans = action.payload.filter(
            (sp: any) => sp.remainingLife > 0
          );
          state.customerPlaystationPlans = filteredPlaystationPlans;
        }
      )
      .addCase(
        getCustomerPlaystationPlans.rejected,
        (state, action: PayloadAction<any>) => {
          state.loadingCustomerPlaystationPlans = false;
          state.error = action.payload;
        }
      )

      .addCase(getCustomerComboPlans.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCustomerComboPlans.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          let filteredComboPlans = action.payload.filter(
            (sp: any) => sp.remainingLife > 0
          );
          state.customerComboPlans = filteredComboPlans;
        }
      )
      .addCase(
        getCustomerComboPlans.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getVendorComboCheckouts.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorComboCheckouts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.vendorComboCheckouts = action.payload;
        }
      )
      .addCase(
        getVendorComboCheckouts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getVendorSoftplayCheckouts.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorSoftplayCheckouts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.vendorSoftplayCheckouts = action.payload;
        }
      )
      .addCase(
        getVendorSoftplayCheckouts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getVendorPlaystationCheckouts.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorPlaystationCheckouts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.vendorPlaystationCheckouts = action.payload;
        }
      )
      .addCase(
        getVendorPlaystationCheckouts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(getVendorArcadeCheckouts.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getVendorArcadeCheckouts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.vendorArcadegamesCheckouts = action.payload;
        }
      )
      .addCase(
        getVendorArcadeCheckouts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(createCheckoutWithPayment.pending, (state, action) => {
        state.checkoutAddLoading = true;
        state.checkoutAddError = false;
      })
      .addCase(
        createCheckoutWithPayment.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.checkoutAddLoading = false;
          state.checkoutAddError = false;
          state.checkoutData = action.payload;
        }
      )
      .addCase(
        createCheckoutWithPayment.rejected,
        (state, action: PayloadAction<any>) => {
          state.checkoutAddLoading = false;
          state.checkoutAddError = true;
        }
      );
  },
});

export const {
  addSoftPlayPlan,
  addArcadeGamesPlan,
  addPlayStationPlan,
  updateSoftPlayAdultAddon,
  updateSoftPlayTimeAddon,
  updatePlaystationAdultAddon,
  updatePlaystationTimeAddon,
  updateSoftPlayCoupon,
  updateArcadGamesCoupon,
  updatePlaystationCoupon,
  updateSoftPlayCouponDiscountValue,
  updateArcadeGamesCouponDiscountValue,
  updateArcadegamesFinalValue,
  updatePlaystationCouponDiscountValue,
  updateSoftplayFinalValue,
  updatePlaystationFinalValue,
  addComboPlan,
  updateComboAdultAddon,
  updateComboTimeAddon,
  updateComboCoupon,
  updateComboCouponDiscountValue,
  updateComboFinalValue,
  updateCustomerSoftplayPlans,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
